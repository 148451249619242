import React, { Suspense, Fragment, useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { routes } from "src/routes";
import { createBrowserHistory } from "history";
import PageLoading from "src/component/PageLoading";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "src/theme";
import AuthContext from "src/context/Auth";
import { Toaster } from "react-hot-toast";
import AuthGuard from "src/component/AuthGuard";
import LogoutTimer from "./component/SessionOutComponent";
import useScript from "./views/pages/BlackJack/GamePlay/useScript";
import { GameSound } from "./views/pages/Ludo/utils/sound";



const history = createBrowserHistory();


export const gameSound = new GameSound();

function App() {
  const theme = createTheme();
  useScript("BlackJack/js/lib/jquery-3.5.1.min.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AuthContext>
            <Toaster
              position="bottom-center"
              autoClose={3000}
              reverseOrder={false}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              // theme="light"
              theme={theme.palette.type}
            />
            <LogoutTimer />
            <Router history={history}>
              <RenderRoutes data={routes} />
            </Router>
          </AuthContext>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

function RenderRoutes(props) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {props.data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const Layout = route.layout || Fragment;
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}
