
import { publish } from "../ChessBoard/event";
import {socket2 } from "../Connection/socket";
import { OnConnectToMaster, OnLocalRoomListUpdate, OnPlayerEnterRoom, OnPlayerLeftRoom, OnPlayerList, OnRoomListing, OnRoomPropertiesChange, ScoketleaveRoom, SocketOnPlayerEnterRoom, SocketOnPlayerLeftRoom, SocketOnRoomListUpdate, SocketOnRoomPropertiesChange, SocketPlayerList, SocketRoomListing, SocketcreateRoom, SocketgetRoomProperties, SocketjoinRoom, SocketraiseEvent, SocketreJoinRoom, SocketsetRoomProperties, onCreateRoom, onGetRoomProperties, onJoinRoom, onJoinRoomFailed, onRaiseEvent } from "../constants/socketKeys";
import { delay, showToast } from "../utils";


export const chessEvents = () => {

    socket2.on('connect', () => {
        sessionStorage.setItem('isConnected', true);
        setTimeout(() =>  {
            console.log("chess client connection to this socket2.id", socket2.id);
            publish(OnConnectToMaster, socket2.id)
        }, 1000);
    });

    socket2.on('disconnect', () => {
      showToast('error', 'Server disconnected');
    });
    
    // ** on Create room
    socket2.on(SocketcreateRoom, function (msg) {   
        console.log('SocketcreateRoom from server: chess', msg);
        // createdRoom = (msg)
        publish(onCreateRoom, msg);
        sessionStorage.setItem('isConnected', false);
    });

    // ** on Room Join
    socket2.on(SocketjoinRoom, function (msg) {
        console.log('SocketjoinRoom from server: chess', msg);
        if (msg.success == false) {
            // console.log('SocketjoinRoom failed server:', msg);
            // showToast('error',msg.message)  
            publish(onJoinRoomFailed, msg);
        } else {
           publish(onJoinRoom, msg)
        }
    });

    // ** on Re-Room Join
    socket2.on(SocketreJoinRoom, function (msg) {
        console.log('SocketreJoinRoom from server:', msg);
        if (msg.success == false) {
            // console.log('SocketjoinRoom failed server:', msg);
            publish(onJoinRoomFailed, msg);
            
        } else {
            publish(onJoinRoom, msg);
        }
    });

     //** on player left room */
    socket2.on(SocketOnPlayerLeftRoom, function (msg) {
        // console.log('OnPlayerLeftRoom from server:', msg);
        publish(OnPlayerLeftRoom, msg.data)
    });
    
    //** on player leave room */
    socket2.on(ScoketleaveRoom, function (msg) {
        // showToast('info',"Player leave the room");
        // console.log('ScoketleaveRoom from server:', msg);
    });
    
    // ** GET ROOM LISTING
    socket2.on(SocketRoomListing, function (msg) {
        // console.log('SocketRoomListing from server:', msg);
        publish(OnRoomListing, msg);
    });

    // ** GET PLAYER LISTING OF LOCAL PLAYER
    socket2.on(SocketPlayerList, (players) => {
        publish(OnPlayerList, players);
    })

    // **  GET ROOM PROPERTIES
    socket2.on(SocketgetRoomProperties, (data) => {
        // console.log("SocketgetRoomProperties>>>>",data)
        publish(onGetRoomProperties, data);
    })

    //** ON PLAYER ENTER ROOM */
    socket2.on(SocketOnPlayerEnterRoom, function (msg) {
        // console.log('OnPlayerEnterRoom from server:', msg);
        publish(OnPlayerEnterRoom, msg.data) 
    });

    //** ON ROOM PROPERTY CHANGE */
    socket2.on(SocketOnRoomPropertiesChange, function (msg) {
        console.log('SocketOnRoomPropertiesChange from server:', msg);
        publish(OnRoomPropertiesChange, msg)
    });

    // ** ON SOCKET RAISE EVENT
    socket2.on(SocketraiseEvent, function (msg) {
        console.log('SocketraiseEvent from server chess:', msg);
        if(msg.success === false) {
        //    showToast('error', msg.message);
           return;
        }
        publish(onRaiseEvent, msg)
    });

    // ON ROME LIST UPDATE
    socket2.on(SocketOnRoomListUpdate , (data) => {
        // console.log('SocketOnRoomListUpdate from server:', data);
        publish(OnLocalRoomListUpdate)
    })
    
}





